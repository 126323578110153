.swal2-confirm {
  width: 100%;
  font-family: var(--popup-font-family) !important;
  color: var(--popup-font-color) !important;
}

.swal2-cancel {
  width: 100%;
  font-family: var(--popup-font-family) !important;
  color: var(--popup-font-color) !important;
}

.swal2-html-container {
  font-size: 16px;
  margin-bottom: 27px;
}

.swal2-content {
  font-size: 16px !important; 
  color: #666666 !important;
  margin-top: 10px !important;
}

.swal2-image {
  max-height: 80px !important;
}

.swal2-title {
  font-size: 21px !important;
  color: black !important;
}

.swal2-popup {
  border-radius: 25px !important;
  width: 22em !important;
  font-family: var(--popup-font-family) !important;
}

.swal2-close {
  margin-right: 5px !important;
  margin-top: 5px !important;
}

.swal2-actions {
  margin: 0 !important;
}
