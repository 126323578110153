/*
'      ____                        _____  __          __           
'     / __ ) ____ _ _____ ___     / ___/ / /_ __  __ / /___   _____
'    / __  |/ __ `// ___// _ \    \__ \ / __// / / // // _ \ / ___/
'   / /_/ // /_/ /(__  )/  __/   ___/ // /_ / /_/ // //  __/(__  ) 
'  /_____/ \__,_//____/ \___/   /____/ \__/ \__, //_/ \___//____/  
'                                          /____/                  
*/

  html{
    font-family: 'Roboto', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    box-sizing: border-box;
  }

  body{
      text-align: left !important;
  }


/*
  '      ____                ____ _  __    
  '     / __ \ _____ ____   / __/(_)/ /___ 
  '    / /_/ // ___// __ \ / /_ / // // _ \
  '   / ____// /   / /_/ // __// // //  __/
  '  /_/    /_/    \____//_/  /_//_/ \___/ 
  ' 
*/

    .header-profile {
        height: '100px';
        width: '100%';
        display: 'flex';
        align-items: 'center';
        background-color: 'rgb(52, 57, 76)';
        padding-left: '10px';
        padding-right: '10px';
        justify-content: 'space-between';
        @media (max-width: 600px) {
            position: fixed;
            top: 0;
            left: 0;
            height: 70px;
            width: 100%;
            display: flex;
            align-items: center;
            z-index: 10;
        }
    }
  
    .header-profile a {
        color: '#ffffff';
        text-decoration: 'none';
    }
    
    .profile-header_wrap {
        padding: 2rem 1rem;
        background-color: #ffffff;
        @media (max-width: 600px) {
            margin-top: 4rem;
        }
    }
  
    .profile-header {
        max-width: 720px;
        margin: 0 auto;
        display: flex;
        @media (max-width: 600px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
        flex-direction: row-reverse;
        justify-content: space-between;
        text-align: left;
    }

    .profile-header  .avatar {
        height: 80px;
        width: 80px;
        margin: 1rem 0;
    }
    
    .avatar {
      height: 100px;
      width: 100px;
      margin-left: 1rem;
      border-radius: 50%;
      background-size: cover;
      background-position-x: 50%;
      background-position-y: 50%;
      overflow-y: hidden;
      overflow-x: hidden;
  
      .fileInput {
        width:100%;
        height:100%;
        opacity:0;
  
      }
    }
  
  
  
  .vatom-count {
    font-size: 1.5rem;
    font-weight: 400;
    span {
      font-weight: 200;
    }
  }
  
  .profile-display_name {
    h2 {
      margin: 0;
      font-weight: 400;
      font-size: 2.25rem;
    }
    p {
      color: $blue;
      font-size: 1.25rem;
      margin: 0;
      margin-top: 1rem;
    }
  
  }
  
  .profile-form_wrap {
    max-width: 720px;
    margin: 0 auto;
    padding: 3rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 600px) {
      padding: 3rem 1rem;
    }
  
  }
  
  .profile-col {
    @media (max-width: 600px) {
      width: 100%;
    }
  
    width: 46%;
  
  }
  
  .merge-message {
    display: block;
    padding: .25rem 2rem 2rem 2rem;
    color: $gray;
    font-size: .9rem;
    text-align: center;
  }
  
  .profile-save_wrap {
  
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    transform: translateY(100%);
    background-color: $white;
    box-shadow: 0px -2px 10px 0px rgba($gray-lt, 0.50);
    transition: transform .2s ease-in-out;
    &.is-open {
      transform: translateY(0);
    }
    @media (max-width: 600px) {
      display: none;
    }
    display: block;
  }
  
  .profile-save {
    max-width: 720px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    [data-react-toolbox="button"] {
      margin: 0 1rem;
    }
  }
  
  .profile-save_mobile {
  
    opacity: 0;
    > a {
      color: $white;
      font-size: 1.2rem;
      margin-left: 1rem;
    }
    &.is-visible {
      opacity: 1
    }
    @media (max-width: 600px) {
      display: flex;
    }
  
    display: none;
  
  }

/*
'      ____                           __                     
'     /  _/____  _   __ ___   ____   / /_ ____   _____ __  __
'     / / / __ \| | / // _ \ / __ \ / __// __ \ / ___// / / /
'   _/ / / / / /| |/ //  __// / / // /_ / /_/ // /   / /_/ / 
'  /___//_/ /_/ |___/ \___//_/ /_/ \__/ \____//_/    \__, /  
'                                                   /____/ 
*/


.dropdown {
  width: 200px;
  border-bottom: 0;
}

.menuItem {
  width: 152px !important;
}
.field {
  display: -webkit-flex;
  /* Safari */
  -webkit-flex-direction: row-reverse;
  /* Safari 6.1+ */
  display: flex;
  flex-direction: row-reverse;
}


.appBar {
  background-color: #fff;
  height: 72px;
  max-width:100vw;
}

.active
{
  color:#41749D;
}

.pointer
{
  top:0 !important;
  height:4px;
  background-color:#41749D;
}
.tab{
color:red;
}

.empty-illustration {
  width: 100%;
  height: 100%;
}
.empty-illustration > img {
  width: 375px;
  height: auto;
}
.empty-illustration > p {
  width: 295px;
  margin-left: calc(50% - 147px);
  margin-top: -80px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  text-align: center;
  color: #4a4a4a;
}

.appBar {
  background-color: #fff;
  height: 72px;
  max-width:100vw;

}

.mobile {
  display: none;
}
@media (max-width: 600px) {
  .mobile {
    display: inline;
  }
}
@media (max-width: 600px) {
  .desktop {
    display: none;
  }
}
.search {
  display: flex;
}
.search .search-input {
  background-color: transparent;
  color: #4a4a4a;
  outline-style: none;
  border-style: none;
  font-size: 1.8rem;
  font-weight: 100;
  flex: 1;
}
.page-padding {
  padding-left: 8%;
  padding-right: 8%;
}
@media (max-width: 600px) {
  .page-padding {
    padding-left: 0;
    padding-right: 0;
  }
}
.inventory {
  width: 100%;
  height: 100%;
  /*.header {
   height: 100px;
   width: 100%;
   display: flex;
   align-items: center;
   background-color: rgb(52, 57, 76);
   @media (max-width: 600px) {
   height: 70px
   }
   .container {
   max-width: 1240px;
   margin-left: auto;
   margin-right: auto;
   padding-left: 20px;
   padding-right: 20px;
   width: 100%;
   .wrap {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   a {
   width: 50px;
   .avatar {
   height: 50px;
   width: 50px;
   margin-left: 1rem;
   border-top-left-radius: 50%;
   border-top-right-radius: 50%;
   border-bottom-right-radius: 50%;
   border-bottom-left-radius: 50%;
   background-size: cover;
   background-position-x: 50%;
   background-position-y: 50%;
   overflow-y: hidden;
   overflow-x: hidden;
   }
   }
   .search,.close{
   color:$white;
   :hover{
   color:$blue;
   }
   }
   .search-input{
   background-color: transparent;
   color: rgb(248, 248, 248);
   outline-style: none;
   border-style:none;
   width: 180px;
   font-size: 1.8rem;
   font-weight: 100;
   @media (min-width: 600px) {
   font-size: 2.5rem;
   width: 250px;
   }
   }
   }
   }
   }
   */
}
.inventory .settings-menu {
  padding: 17px;
  color: #4a4a4a;
  font-size: 12px;
}
.inventory .settings-menu td {
  height: 28px;
  padding: 0;
  margin: 0;
}
.inventory .settings-menu td label {
  height: 28px !important;
  margin: 0;
}
.inventory .settings-menu td:nth-child(2) {
  text-align: right;
}
.inventory .settings-menu p {
  color: #979797;
}
.inventory .settings-menu table {
  width: 100%;
}
.inventory .header .avatar {
  height: 40px;
  width: 40px;
}
.inventory .header .icon {
  height: 24px;
  width: 24px;
  margin-left: 20px;
  margin-right: 20px;
}
.inventory .header .tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inventory .header .tab svg {
  height: 16px;
  width: 16px;
  margin-right: 16px;
}
.inventory .main {
  width: 100%;
  height: calc(100% - 144px);
  background-color: #fff;
}
@media (max-width: 600px) {
  .inventory .main {
    height: calc(100% - 74px);
  }
}
.inventory .overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 303;
}
.inventory .grid {
  position: relative;
  width: 100%;
}
.inventory .grid > a {
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
}
.category {
  color: #212121;
  border-bottom: 0;
  cursor: pointer;
  font-family: ProximaNova, Helvetica Neue, Helvetica, Arial;
  font-size: 28px;
  font-weight: 300;
  color: #4a4a4a;
}
.category > img {
  color: #212121;
  margin-left: 16px;
  vertical-align: middle;
}
.category .category-dropdown {
  width: 248px;
  box-shadow: 0px 0px 30px #979797;
  position: absolute;
  top: 0;
  background-color: #fff;
  z-index: 5;
  font-size: 17px;
  font-weight: normal;
  color: #000;
}
@media (max-width: 600px) {
  .category .category-dropdown {
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.category .category-dropdown .category-filter {
  width: 100%;
  height: 44px;
  vertical-align: middle;
  margin-left: 10px;
  margin-top: 10px;
}
.settings-mobile .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 5;
  transition: all 300ms linear;
}
.settings-mobile .settings {
  transition: all 300ms linear;
  position: absolute;
  bottom: -312px;
  left: 0;
  right: 0;
  height: 312px;
  z-index: 6;
  background-color: #f7f7f7;
}
.settings-mobile .settings > div {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  padding-right: 15px;
  padding-left: 15px;
  display: block;
}
.settings-mobile .settings > div p {
  margin-left: 10px;
  color: #979797;
}
.settings-mobile .settings > div table {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.settings-mobile .settings > div table td:nth-child(2) {
  text-align: right;
}
.settings-mobile .settings > div .order {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  margin-bottom: 40px;
  background-color: #fff;
  align-items: center;
  align-content: center;
  border-radius: 20px;
}
.settings-mobile .settings > div .order > div {
  color: #979797;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  line-height: 40px;
}
.settings-mobile .settings > div .order .selected {
  background-color: #5393c6;
  border-radius: 20px;
  height: 100%;
  color: #fff;
}
.settings-mobile.active .overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.settings-mobile.active .settings {
  bottom: 0;
}

.nag-banner-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: #5393c6;
}
@media (max-width: 600px) {
  .nag-banner-desktop {
    display: none;
  }
}
.nag-banner-desktop > .nag-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
}
.nag-banner-desktop > .nag-banner > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nag-banner-desktop > .nag-banner > div small {
  color: #fff;
}
.nag-banner-desktop > .nag-banner > div svg {
  margin-left: 10px;
}
.nag-banner-mobile {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: rgba(246, 246, 246, 0.88);
  transform: translateY(80px);
  transition: transform 0.4s ease;
}
@media (min-width: 600px) {
  .nag-banner-mobile {
    display: none;
  }
}
.nag-banner-mobile.is-up {
  transform: translateY(0);
}
.nag-banner-mobile > .nag-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.nag-banner-mobile > .nag-banner > div {
  display: flex;
  align-items: center;
}
.nag-banner-mobile > .nag-banner img {
  max-height: 50px;
}




/*
'      __                  _      
'     / /   ____   ____ _ (_)____ 
'    / /   / __ \ / __ `// // __ \
'   / /___/ /_/ // /_/ // // / / /
'  /_____/\____/ \__, //_//_/ /_/ 
'               /____/           
*/

.base {
  height: 100%;
  background-color: #5f2c82;
  background-image: linear-gradient(147deg, #49a09d, #5f2c82);
  display: flex;
  justify-content: center;
  align-items: center;
}
.base .text {
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.base .frame {
  position: relative;
  width: 969px;
  height: 488px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  background-color: #252b3f;
  padding: 48px;
}
@media (max-width: 799px) {
  .base .frame {
    /** Prevent body scrolling under the card when on mobile */
    width: 0px;
    height: 0px;
  }
}
.base .guide {
  display: block;
  height: 100%;
  width: 320px;
  min-width: 250px;
  opacity: 0.9;
}
.base .guide img {
  max-width: 45px;
}
.base .guide h1 {
  padding-top: 44px;
  font-size: 36px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  color: #f8f8f8;
}
.base .guide h2 {
  padding-top: 65px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #f8f8f8;
}
@media (max-width: 799px) {
  .base .guide {
    display: none;
  }
}
.base .card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f8f8f8;
  padding: 56px 48px;
  z-index: 1;
  position: relative;
  right: 0;
  width: 440px;
  height: 568px;
  border-radius: 3px;
}
.base .card .image-holder {
  width: 112px;
  height: 112px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
@media (max-width: 740px) {
  .base .card .image-holder {
    display: none;
  }
}
.base .card .back {
  cursor: pointer;
  position: absolute;
  left: 24px;
  top: 36px;
  width: 10px;
  height: 16px;
}
@media (max-width: 740px) {
  .base .card .back {
    left: 14px;
    top: 27px;
  }
}
.base .card button {
  margin-bottom: 25px;
}
.base .card form {
  width: 100%;
}
@media (max-width: 799px) {
  .base .card {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 67px 24px 0px 24px;
    background-color: #fff;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
  }
}


button.custom {
  cursor: pointer;
  height: 64px;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  margin: 0;
}
button.custom .icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  width: 24px;
  height: 24px;
}
button.custom.pill {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 95px;
  padding: 1.25rem 0.5rem;
  color: #f8f8f8;
  text-align: center;
}
button.custom.isBlue {
  background-color: #5393c6;
  color: #fff;
}
button.custom.isBlue :hover {
  background-color: #4086bf;
}
button.custom.isRed {
  background-color: #db5029;
  color: #fff;
}
button.custom.isRed :hover {
  background-color: #c94622;
}
.split-button {
  display: flex;
  width: 100% !important;
}
.split-button button.custom {
  width: 50%;
  display: block;
  padding: 1.05rem 0.5rem;
  color: #4a4a4a;
  border-width: 1px;
  border-color: #979797;
  border-style: solid;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  background-color: #fff;
}
.split-button button.custom :hover {
  background-color: #ededed;
}
.split-button .split {
  text-align: center;
  font-family: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  font-weight: 100;
}
.split-button .left {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.split-button .right {
  border-left: none;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.form.center {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.form.center > div {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.form.text {
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  text-align: center;
  color: #4a4a4a;
}
@media (max-width: 740px) {
  .form.text {
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    text-align: center;
  }
}
@media (max-height: 320px) {
  .form.text {
    font-size: 16px;
    margin-top: -42px;
  }
}
.form.subtext {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: #4a4a4a;
}
@media (max-width: 740px) {
  .form.subtext {
    font-weight: 300;
  }
}





/*
'      __  __                  __           
'     / / / /___   ____ _ ____/ /___   _____
'    / /_/ // _ \ / __ `// __  // _ \ / ___/
'   / __  //  __// /_/ // /_/ //  __// /    
'  /_/ /_/ \___/ \__,_/ \__,_/ \___//_/     
'                                      
*/

@media screen and (orientation:landscape)
and (min-device-width: 319px) 
and (max-device-width: 850px) {
  .folder-header{
    display: none;
  }
  .folder-back{
    padding-left: 50px;
  }
}


.menuItem{
  width:152px!important;
}

.field
{
  display: -webkit-flex; /* Safari */
  -webkit-flex-direction: row-reverse; /* Safari 6.1+ */
  display: flex;
  flex-direction: row-reverse;
}

/*
'      __  ___ _            
'     /  |/  /(_)_____ _____
'    / /|_/ // // ___// ___/
'   / /  / // /(__  )/ /__  
'  /_/  /_//_//____/ \___/  
'   
*/

.activated {
  width: 100%;
  height: 100%;
  background-color: #363636;
  position: fixed;
}
.activated header {
  display: flex;
  justify-content: space-between;
}
.activated header > div {
  display: flex;
  align-items: center;
}
.activated header .name {
  font-size: 2rem;
  color: #fff;
}
.activated .close {
  width: 45px;
  height: 45px;
  display: block;
}
.activated .vatom {
  margin: 40px;
}
.activated .container {
  width: 100%;
  height: 100%;
}
.activated .container > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
}
.icon-fullscreen {
  background-color: #fff;
  width: 100%;
  height: 100%;
  transition: all 300ms linear;
}
.icon-fullscreen header {
  transition: all 100ms linear;
  padding-left: 8%;
  padding-right: 8%;
  height: 112px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon-fullscreen header svg {
  width: 25px;
  height: 25px;
}
.icon-fullscreen header > div {
  display: flex;
  align-items: center;
}
.icon-fullscreen header > div > .item {
  padding-right: 12px;
  padding-left: 12px;
}
.icon-fullscreen header .name {
  font-size: 28px;
  color: #4a4a4a;
}
@media (max-width: 600px) {
  .icon-fullscreen header .name {
    display: none;
  }
}
.icon-fullscreen header .name {
  color: #4a4a4a !important;
}
.icon-fullscreen header.fullscreen .name {
  color: #fff !important;
}
.icon-fullscreen .container {
  width: 100%;
  height: calc(100% - 112px);
  top: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-fullscreen .container > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 311px;
  width: 311px;
  overflow: hidden;
}
.icon-fullscreen.active {
  background-color: #f5f5f5 !important;
}
.icon-fullscreen.fullscreen {
  background-color: #282828 !important;
}
.cardholder {
  width: 100%;
  height: calc(100% - 122px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  position: fixed;
  top: 112px;
}
.cardview {
  border-radius: 20px;
  overflow: hidden;
  position: fixed;
  z-index: 5;
}
.cardview .close {
  position: absolute;
  display: none;
  z-index: 999;
  right: 0;
  margin: 10px;
  width: 30px;
  height: 30px;
}

.cardview-full {
  width: 100% !important;
  border-radius: 0 !important;
}
